<template>
   <CTabs tabs class="nav-underline nav-underline-primary">
        <CTab active>
          <template slot="title">
            <CIcon name="cil-task" />&nbsp;Filtre
          </template>
          <CForm ref="form" style="height: 100vh; padding: 10px 10px 100px; overflow-y: scroll;">
            <CRow>
              <CCol col="6">
                <CInput
                    label="Sipariş no"
                    placeholder="1000000"
                    horizontal
                    type="text"
                    v-on:keyup.enter="filter"
                    v-model="data.id"
                />
              </CCol>
              <CCol col="3" sm="2">
                <CButton type="submit" size="sm" color="primary" @click="filter">
                  <CIcon name="cil-check-circle"/>
                  Filtrele
                </CButton>
              </CCol>
              <CCol col="3" sm="2">
                <CButton type="reset" size="sm" color="danger" @click="clearFilters">
                  <CIcon name="cil-ban"/>
                  Temizle
                </CButton>
              </CCol>
            </CRow>
            <hr/>
            <CRow>
              <CCol>
                <CInput
                    label="Sipariş Zamanı"
                    horizontal
                    style="width: 50%"
                    type="date"
                    v-model="data.startTime"
                />
                <CInput
                    label=" "
                    horizontal
                    style="width: 50%"
                    type="date"
                    v-model="data.endTime"
                />
              </CCol>
            </CRow>
            <hr/>
            <CRow>
              <CCol sm="6">
                <CInput
                    label="Ad, Soyad"
                    placeholder="Ad, Soyad"
                    horizontal
                    type="text"
                    v-on:keyup.enter="filter"
                    v-model="data.sAddressPersonName"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                    label="E-mail"
                    placeholder="Email adres"
                    horizontal
                    type="text"
                    v-on:keyup.enter="filter"
                    v-model="data.customerEmailAddress"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CInput
                    label="Telefon"
                    placeholder="Telefon numarası"
                    horizontal
                    type="text"
                    v-on:keyup.enter="filter"
                    v-model="data.customerPhoneNumber"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                    label="Kargo takip"
                    placeholder="Takip numarası"
                    horizontal
                    type="text"
                    v-on:keyup.enter="filter"
                    v-model="data.shippingTrackingNumber"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CInput
                    label="Ülke"
                    placeholder="Ülke ismi"
                    horizontal
                    type="text"
                    v-on:keyup.enter="filter"
                    v-model="data.countryName"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                    label="Şehir"
                    placeholder="Şehir ismi"
                    horizontal
                    type="text"
                    v-on:keyup.enter="filter"
                    v-model="data.city"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CInput
                    label="Fatura"
                    placeholder="Fatura numarası"
                    horizontal
                    type="number"
                    v-on:keyup.enter="filter"
                    v-model="data.invoiceNumber"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                    label="Promosyon"
                    placeholder="Promosyon kodu"
                    horizontal
                    type="text"
                    v-on:keyup.enter="filter"
                    v-model="data.promocode"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6" style="margin-bottom: 15px">
                <CRow>
                  <CCol sm="3"> <label>Sipariş durumu</label></CCol>
                  <CCol sm="9">
                    <Multiselect
                        placeholder="Sipariş durumunu seç"
                        horizontal
                        select-label="Seçiniz"
                        selected-label="Seçildi"
                        deselect-label="Kaldır"
                        size="sm"
                        v-model="data.orderStatus"
                        :options="orderStatusTypes"
                        :multiple="true"
                        label="label"
                        track-by="label"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol sm="6">
                <CRow>
                  <CCol sm="3"> <label>Kargo</label></CCol>
                  <CCol sm="9">
                    <Multiselect
                        style="width: 100%"
                        placeholder="Kargo firması seç"
                        horizontal
                        size="sm"
                        select-label="Seçiniz"
                        selected-label="Seçildi"
                        deselect-label="Kaldır"
                        v-model="data.shippingCompany"
                        :options="shippingCompanies"
                        :multiple="true"
                        label="label"
                        track-by="label"
                    />
                  </CCol>
                </CRow>
              </CCol>

            </CRow>
            <CRow>
              <CCol sm="6">
                <CSelect
                    label="Ödeme"
                    placeholder="Ödeme türü seç"
                    horizontal
                    :options="paymentTypes"
                    :value.sync="data.paymentType"
                >
                </CSelect>
              </CCol>

              <CCol sm="6">
                <CInput
                    label="Ödeme ID"
                    placeholder="Ödeme ID"
                    horizontal
                    type="text"
                    v-on:keyup.enter="filter"
                    v-model="data.payerID"
                />
              </CCol>

            </CRow>
            <CRow>
              <CCol sm="6">
                <CSelect
                    label="Fatura hazırlama"
                    placeholder="Hepsi"
                    horizontal
                    :options="comboDataList"
                    :value.sync='data.invoiceReadyPrintFlag'
                >
                </CSelect>
              </CCol>
              <CCol sm="6">
                <CSelect
                    label="Fatura yazdırma"
                    placeholder="Hepsi"
                    horizontal
                    :options="comboDataList"
                    :value.sync='data.invoicePrintedFlag'
                >
                </CSelect>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CSelect
                    label="Kontrol"
                    placeholder="Hepsi"
                    horizontal
                    :options="comboDataList"
                    :value.sync='data.checkedByUser'
                >
                </CSelect>
              </CCol>
              <CCol sm="6">
                <CSelect
                    label="E-Fatura Tabi"
                    placeholder="Hepsi"
                    horizontal
                    :options="comboDataList"
                    :value.sync='data.companyEinvoiceMember'
                >
                </CSelect>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CSelect
                    label="Bilgilendirme"
                    placeholder="Hepsi"
                    horizontal
                    :options="comboDataList"
                    :value.sync='data.informShippingCompanyTime'
                >
                </CSelect>
              </CCol>
              <CCol sm="6">
                <CSelect
                    label="Hediye"
                    placeholder="Hepsi"
                    horizontal
                    :options="comboDataList"
                    :value.sync='data.giftFlag'
                >
                </CSelect>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CSelect
                    label="GİB"
                    placeholder="Hepsi"
                    horizontal
                    :options="comboDataList"
                    :value.sync='data.gibFlag'
                >
                </CSelect>
              </CCol>
              <CCol sm="6">
                <CSelect
                    label="ETTN"
                    placeholder="Hepsi"
                    horizontal
                    :options="comboDataList"
                    :value.sync='data.ettn'
                >
                </CSelect>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CSelect
                    label="3DSecure"
                    placeholder="Hepsi"
                    horizontal
                    :options="comboDataList"
                    :value.sync='data.secure3DFlag'
                >
                </CSelect>
              </CCol>
            </CRow>
          </CForm>
        </CTab>
    </CTabs>
</template>

<script>
  import Multiselect from 'vue-multiselect'
   export default{
       name: "OrdersFilter",
       props:{
           validated: Boolean,
           params: Object,
           actionType: String,
           filter: Function
       },
       components:{
        Multiselect
       },
       computed:{
        orderStatusTypes: function() {
          let data = []
          this.$store.getters.orderStatusTypes.map(r => data.push({value:r.type, label: r.friendlyType}))
          return data
        },
        shippingCompanies: function(){
          let data = []
          this.$store.getters.shippingCompanyList.map(r => data.push({value:r.id, label: r.name}))
          return data
        }
       },
       watch:{
           params: function(val){
               this.data = {...val};
           },
       },
       data: function(){
           return{
               data: this.params ? {...this.params} : {},
               comboDataList: [
                {label: "Hepsi", value: ""},
                {label: "Evet", value: "true"},
                {label: "Hayır", value: "false"}
              ],
              paymentTypes: [
                {value: '1', label: 'PayPalEC'},
                {value: '2', label: 'EFT'}, 
                {value: '3', label: 'CreditCard'},
                {value: '4', label: 'FreePromo'},
                {value: '5', label: 'DebitCard'},
                {value: '6', label: 'Cash'},
                {value: '7', label: 'GiroGate'},
                {value: '8', label: 'GarantiPay'},
                {value: '10', label: 'IyzicoPay'}
              ]
           }
       },
       methods:{
            getCities: async function(){
                let params = {countryId: this.data.countryId}
                await this.$store.dispatch('allCities_list', params)
            },
            clearFilters: function(){
                this.data = {}
                this.filter()
            }
       }
   }
</script>